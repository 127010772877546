<template>
    <div class="redirect-form" style="padding-top: 40px;">
        <a-row type="flex" justify="center" align="top">
            <a-col :span="14">
                <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:4}"
                        :wrapper-col="{span:18}">
                    <a-form-item :colon="false">
                        <template #label>
                            <h3>{{$t($route.meta.action)}}</h3>
                        </template>
                    </a-form-item>
                    <a-form-item :label="$t('redirect.name')" name="name">
                        <a-input :disabled="$route.meta.action==='redirect.edit'" v-model:value="formState.name"
                                 :placeholder="$t('plz.input')"/>
                    </a-form-item>
                    <a-form-item :label="$t('device.userAttr')" name="user">
                        <a-select
                                ref="select"
                                :disabled='!ifAdmin||$route.meta.action==="redirect.edit"'
                                :placeholder="$t('plz.select')"
                                style="width: 100%"
                                allowClear
                                showSearch
                                :show-arrow="false"
                                :filter-option="false"
                                @search="handleSearch"
                                @change="userChange"
                                :options="formState.opts"
                                v-model:value="formState.user"/>
                    </a-form-item>
                    <a-form-item :label="$t('redirect.rangeForm')">
                        <a-radio-group name="range" v-model:value="formState.range">
                            <a-radio value="GLOBAL">{{$t('DOMAIN')}}</a-radio>
                            <a-radio value="SPECIFY_DEVICE">{{$t('redirect.rangeFormName',{name:$t('DEVICE')})}}
                            </a-radio>
                            <a-radio value="SPECIFY_GROUP">{{$t('redirect.rangeFormName',{name:$t('GROUP')})}}</a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item v-if="formState.range==='SPECIFY_DEVICE'" :label-col="{span:0}"
                                 :wrapper-col="{span:18,offset:4}">
                        <div class="flex right">
                            <a @click="()=>{device.getDeviceALl();device.show=true}" style="margin-right: 8px;">{{$t('add')}}</a>
                            <a v-if="rowSelection.selectedRowKeys.length>0" @click="device.removeList">{{$t('del')}}</a>
                            <a v-else disabled>{{$t('del')}}</a>
                        </div>
                        <a-table
                                rowKey="id"
                                :scroll="{ x:400, y: 300 }"
                                :columns="rangeDeviceLeftColumns"
                                :data-source="device.rangeDevice"
                                :row-selection="rowSelection"/>
                    </a-form-item>
                    <a-form-item v-if="formState.range==='SPECIFY_GROUP'" :label-col="{span:0}"
                                 :wrapper-col="{span:18,offset:4}">
                        <a-tree-select
                                v-model:value="formState.rangeGroup"
                                show-search
                                allowClear
                                treeNodeFilterProp="title"
                                style="width: 100%"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="formState.rangeGroupTreeData"
                                :placeholder="$t('plz.select')"
                                tree-default-expand-all/>
                    </a-form-item>
                </a-form>
            </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="top">
            <a-col :span="14">
                <a-form ref="formRef2" :model="formState2" :rules="rules" :label-col="{span:4}"
                        :wrapper-col="{span:18}">
                    <a-form-item :colon="false">
                        <template #label>
                            <h4>{{$t('redirect.server')}}</h4>
                        </template>
                    </a-form-item>
                    <a-form-item label="NMS" style="margin-bottom: 0">
                        <div class="flex left">
                            <a-form-item name="NMSIp" style="width: 330px;">
                                <a-input v-model:value="formState2.NMSIp" :placeholder="$t('plz.inputIp')"/>
                            </a-form-item>
                            <a-form-item name="NMSPort" style="width: 200px;margin-left: 40px;">
                                <a-input v-model:value="formState2.NMSPort" :placeholder="$t('plz.inputPort')"/>
                            </a-form-item>
                        </div>
                    </a-form-item>
                    <a-form-item label="DRP" style="margin-bottom: 0">
                        <div class="flex left">
                            <a-form-item name="DRPIp" style="width: 330px;">
                                <a-input v-model:value="formState2.DRPIp" :placeholder="$t('plz.inputIp')"/>
                            </a-form-item>
                            <a-form-item name="DRPPort" style="width: 200px;margin-left: 40px;">
                                <a-input v-model:value="formState2.DRPPort" :placeholder="$t('plz.inputPort')"/>
                            </a-form-item>
                        </div>
                    </a-form-item>
                    <a-form-item label="DMCLD" style="margin-bottom: 0">
                        <div class="flex left">
                            <a-form-item name="DMCLDIp" style="width: 330px;">
                                <a-input v-model:value="formState2.DMCLDIp" :placeholder="$t('plz.inputIp')"/>
                            </a-form-item>
                            <a-form-item name="DMCLDPort" style="width: 200px;margin-left: 40px;">
                                <a-input v-model:value="formState2.DMCLDPort" :placeholder="$t('plz.inputPort')"/>
                            </a-form-item>
                        </div>
                    </a-form-item>
                </a-form>
            </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="top">
            <a-col :span="14">
                <a-form ref="formRef3" :model="formState3" :rules="rules" :label-col="{span:4}"
                        :wrapper-col="{span:18}">
                    <a-form-item :colon="false">
                        <template #label>
                            <h4>PROVISION</h4>
                        </template>
                    </a-form-item>
                    <a-form-item has-feedback >
                        <template #label>
                            <a-tooltip>
                                <template #title>{{$t('redirect.PROVISIONPlz')}}</template>
                                <InfoCircleFilled style="color: #929292;margin-right: 4px;"/>
                            </a-tooltip>
                            <span>{{$t('redirect.url')}}</span>
                        </template>
                        <a-input v-model:value="formState3.url" :placeholder="$t('plz.input')"/>
                    </a-form-item>
                    <a-form-item :label="$t('redirect.login')" >
                        <a-input v-model:value="formState3.login" :placeholder="$t('plz.input')"/>
                    </a-form-item>
                    <a-form-item :label="$t('redirect.pwd')" >
                        <a-input-password v-model:value="formState3.pwd" :placeholder="$t('plz.input')"/>
                    </a-form-item>
                    <!-- <a-form-item :label="$t('redirect.auth')" name="auth">
                         <div class="flex left">
                             <a-upload
                                     :beforeUpload="beforeUpload"
                                     v-model:fileList="formState3.fileList"
                                     name="file"
                                     :multiple="false"
                                     :showUploadList="false"
                                     action="https://www.mocky.io/v2/5cc8019d300000980a055e76">
                                 <div class="flex left">
                                     <a-button v-if="formState3.fileList.length===0">{{$t('upload.up')}}</a-button>
                                     <a-button v-else>{{$t('upload.up')}}</a-button>
                                     <a style="margin-left: 10px;" v-if="formState3.fileList.length>0">{{formState3.fileList[0].name}}</a>
                                 </div>
                                 <div style="color: #7f7f7f;">{{$t('redirect.upLimit')}}</div>
                             </a-upload>
                         </div>
                     </a-form-item>-->
                    <a-form-item>
                        <template #label>
                            <div class="flex left column">
                                <h4>{{$t('redirect.desc')}}</h4>
                                <span>({{$t('xuanTian')}})</span>
                            </div>
                        </template>
                        <a-textarea v-model:value="formState3.desc" :placeholder="$t('plz.input')" :rows="4"/>
                    </a-form-item>
                    <a-form-item name="state" v-if="$route.meta.action!=='redirect.edit'">
                        <template #label>
                            <div class="flex left column">
                                <h4>{{$t('redirect.state')}}</h4>
                            </div>
                        </template>
                        <div class="flex left"  >
                            <a-switch v-model:checked="formState3.state"/>
                            <span style="margin-left: 10px;" v-if="formState3.state">{{$t('enable')}}</span>
                            <span style="margin-left: 10px;" v-else>{{$t('disabled')}}</span>
                        </div>
                    </a-form-item>
                    <a-form-item :label-col="{span:0}" :wrapper-col="{span:18,offset:4}">
                        <a-button v-if="$route.meta.action==='redirect.edit'" style="margin-right: 8px;" type="primary"
                                  @click="onEditOk">
                            {{$t('ok')}}
                        </a-button>
                        <a-button v-else style="margin-right: 8px;" type="primary" @click="onOk">
                            {{$t('ok')}}
                        </a-button>
                        <a-button @click="onCancel">{{$t('cancel')}}</a-button>
                    </a-form-item>
                </a-form>
            </a-col>
        </a-row>
        <a-modal :visible="device.show" width="800px" :title="$t('redirect.rangeFormName',{name:$t('DEVICE')})"
                 @cancel="device.handleCancel">
            <a-row :gutter="[24,24]">
                <a-col :span="24">
                    <a-form-item :label="$t('device.searchDevice')" :colon="false">
                        <a-input-search allowClear v-model:value="device.choiceDeviceSearch"
                                        @search="device.getDeviceALl()" :placeholder="$t('device.searchDevicePlz')"/>
                    </a-form-item>
                </a-col>
                <a-col :span="24">
                    <a-table
                            rowKey="id"
                            :scroll="{ x:true, y: 300 }"
                            :columns="rangeDeviceLeftColumns"
                            :data-source="device.choiceDeviceList"
                            :pagination="device.pagination"
                            :row-selection="device.choiceRowSelection"/>
                </a-col>
            </a-row>
            <template #footer>
                <a-button key="back" @click="device.handleCancel">{{$t('cancel')}}</a-button>
                <a-button key="submit" type="primary" @click="device.choiceHandleOk">{{$t('ok')}}</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
    import {reactive, ref, toRaw, onMounted, computed} from 'vue'
    import i18n from "../../../locale/main";
    import {InfoCircleFilled} from '@ant-design/icons-vue'
    import {message} from "ant-design-vue";
    import {useRouter, useRoute} from 'vue-router'
    import {useStore} from 'vuex'
    import {add, getDetail, update} from '../../../service/redirect'
    import {toPage} from "../../../service/device";

    let reg = {
        ip: /^(?=^.{3,255}$)[a-zA-Z0-9_][-a-zA-Z0-9_]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/,
        port: /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{4}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/,
        http: /^(https?):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.${\w*},@?^=%&:/~+#-]*[\w${\w*}@?^=%&/~+#-])?/
    }

    export default {
        components: {
            InfoCircleFilled,
        },
        setup() {
            let t = i18n.global.t

            let router = useRouter()
            let route = useRoute()
            let store = useStore()
            const formRef = ref()
            const formRef2 = ref()
            const formRef3 = ref()
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)
            const rowSelection = reactive({
                selectedRowKeys: [],
                onChange: (selectedRowKeys,) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                },
            });
            const device = reactive({
                show: false,
                removeList() {
                    device.rangeDevice=device.rangeDevice.filter(r=>rowSelection.selectedRowKeys.indexOf(r.id)<0)
                    rowSelection.selectedRowKeys=[]

                },
                choiceHandleOk() {
                    let arr=[...device.choiceRowSelection.selectedRows]
                    let newArr=[]
                    arr.map(r=>{
                        let item=device.rangeDevice.find(n=>n.id===r.id)
                        if(!item){
                            newArr.push(r)
                        }
                    })
                    device.rangeDevice = [...device.rangeDevice,...newArr]
                    device.handleCancel()
                },
                handleCancel() {
                    device.show = false
                    device.choiceDeviceSearch = null
                    device.choiceDeviceList = []
                    device.choiceRowSelection.selectedRowKeys = []
                    device.choiceRowSelection.selectedRows = []
                },
                choiceDeviceList: [],
                choiceRowSelection: {
                    selectedRowKeys: [],
                    selectedRows: [],
                    onChange: (selectedRowKeys, rows) => {
                        device.choiceRowSelection.selectedRowKeys = selectedRowKeys;
                        device.choiceRowSelection.selectedRows = rows
                    },
                },
                rangeDevice: [],
                choiceDeviceSearch: null,
                async getDeviceALl(page, size) {
                    let rs = await toPage({
                        ifAll: true,
                        uid:store.state.user.userInfo.ifAdmin?formState.user:store.state.user.userInfo.id,
                        search: device.choiceDeviceSearch,
                        cursor: page || device.pagination.current,
                        limit: size || device.pagination.pageSize,
                    })
                    device.choiceDeviceList = rs.data.result.records
                    device.pagination.total = rs.data.result.total
                    device.pagination.current = rs.data.result.current
                    device.pagination.pageSize =rs.data.result.size
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    pageSizeOptions: ['10', '20', '30'],
                    showSizeChanger: true,
                    total: 0,
                    showTotal: (total) => t('pages.total', {num: total}),
                    async onChange(val) {
                        await device.getDeviceALl(val, device.pagination.pageSize)
                    },
                    async onShowSizeChange(_, size) {
                        await device.getDeviceALl(device.pagination.current, size)
                    },
                }
            })

            const formState = reactive({
                name: null,
                range: "GLOBAL",


                /*[
                    {
                        key: 'DE10-7611-0130-0083',
                        title: 'DE10-7611-0130-0083',
                        deviceSn: 'DE10-7611-0130-0083',
                        mac: '14:20:5e:0f:83:38',
                    },
                ]*/

                rangeGroup: null,
                rangeGroupTreeData: computed(() => store.state.device.choiceGroupArr),
                user:store.state.user.userInfo.id,
                opts: computed(()=>{
                    return store.state.user.data.map(r=>{
                        r.label=r.attr
                        r.value=r.id
                        return r
                    })
                })
            })
            const rules = {
                name: [{required: true, message: t('plz.input'), trigger: 'blur',},],
                user: [{required: true,validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    },trigger: 'blur',},],
                NMSIp: [{
                    required: true,
                    validator: (rule, val) => {
                        if (val && formState2.NMSPort) {
                            if (reg.ip.test(val) || reg.http.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputIp1'))
                        }

                        if (!val && formState2.NMSPort) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputIp'))
                    },
                    trigger: 'blur',
                },],
                NMSPort: [{
                    required: true,
                    validator: (rule, val) => {
                        if (!val && formState2.NMSIp) {
                            return Promise.reject(t('plz.inputPort'))
                        }
                        if (val && formState2.NMSIp) {
                            if (reg.port.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputPort1'))
                        }

                        if (val && !formState2.NMSIp) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputPort'))
                    },
                    trigger: 'blur',
                },],
                DRPIp: [{
                    required: true,
                    validator: (rule, val) => {
                        if (val && formState2.DRPPort) {
                            if (reg.ip.test(val) || reg.http.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputIp1'))
                        }

                        if (!val && formState2.DRPPort) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputIp'))
                    },
                    trigger: 'blur',
                },],
                DRPPort: [{
                    required: true,
                    validator: (rule, val) => {
                        if (!val && formState2.DRPIp) {
                            return Promise.reject(t('plz.inputPort'))
                        }
                        if (val && formState2.DRPIp) {
                            if (reg.port.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputPort1'))
                        }

                        if (val && !formState2.DRPIp) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputPort'))
                    },
                    trigger: 'blur',
                },],
                DMCLDIp: [{
                    required: true,
                    validator: (rule, val) => {
                        if (val && formState2.DMCLDPort) {
                            if (reg.ip.test(val) || reg.http.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputIp1'))
                        }

                        if (!val && formState2.DMCLDPort) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputIp'))
                    },
                    trigger: 'blur',
                },],
                DMCLDPort: [{
                    required: true,
                    validator: (rule, val) => {
                        if (!val && formState2.DMCLDIp) {
                            return Promise.reject(t('plz.inputPort'))
                        }
                        if (val && formState2.DMCLDIp) {
                            if (reg.port.test(val)) {
                                return Promise.resolve()
                            }
                            return Promise.reject(t('plz.inputPort1'))
                        }

                        if (val && !formState2.DMCLDIp) {
                            return Promise.resolve()
                        }

                        return Promise.reject(t('plz.inputPort'))
                    },
                    trigger: 'blur',
                },],
                url: [{
                    required: true,
                    validator: (rule, val) => {
                        if (reg.http.test(val)) {
                            return Promise.resolve()
                        } else {
                            return Promise.reject(t('plz.inputUrl'))
                        }
                    },
                    trigger: 'blur',
                },],
                login: [{required: true, message: t('plz.inputLogin'), trigger: 'blur',},],
                pwd: [{required: true, message: t('plz.inputPwd'), trigger: 'blur',},],
            }


            const formState2 = reactive({
                NMSIp: null,
                NMSPort: 20006,
                DRPIp: null,
                DRPPort: 3100,
                DMCLDIp: null,
                DMCLDPort: 2002,
            })

            const formState3 = reactive({
                url: null,
                login: null,
                pwd: null,
                auth: null,
                fileList: [],
                desc: '',
                state: true,
            })

            const beforeUpload = (file) => {
                let name = file.name
                if (!/\.(pem|crt|cer|der)$/.test(name)) {
                    message.error(t('upload.err1'))
                    return false
                }
            }

            const validator = {
                range() {
                    if (formState.range === 'GLOBAL') {
                        return true
                    }
                    if (formState.range === 'SPECIFY_DEVICE') {
                        if (device.rangeDevice.length === 0) {
                            message.error(t('redirect.err2'))
                            return Promise.reject(false)
                        }
                        return true
                    }
                    if (formState.range === 'SPECIFY_GROUP') {
                        if (formState.rangeGroup.length === 0) {
                            message.error(t('redirect.err3'))
                            return Promise.reject(false)
                        }
                        return true
                    }
                },
                server() {
                    if (
                        !formState2.NMSIp && formState2.NMSPort &&
                        !formState2.DRPIp && formState2.DRPPort &&
                        !formState2.DMCLDIp && formState2.DMCLDPort&&
                        !formState3.url
                    ) {
                        message.error(t('redirect.err4'))
                        return Promise.reject(false)
                    }
                    return true
                }
            }

            const userChange=async ()=>{
                formState.rangeGroup=null
                await store.dispatch('device/changeChoiceGroupArr', {id:formState.user})
            }

            onMounted(async () => {
                if(ifAdmin.value){
                    store.dispatch('user/search',{searchForm:{search:''},current:1,search:true})
                }
                if (route.meta.action === 'redirect.edit') {
                    if (!route.query.name || !route.query.id) {
                        router.go(-1)
                        return;
                    }
                    let rs = await getDetail({id: route.query.id})

                    let info = rs.data.result
                    formState.name = route.query.name
                    formState.user = info.redirect.uid
                    formState.range = info.redirect.scope

                    if(formState.range === 'SPECIFY_GROUP'&&info.redirectMetadataList.length>0){
                        formState.rangeGroup = parseInt(info.redirectMetadataList[0].scopeValue)
                    }

                    if (formState.range === 'SPECIFY_DEVICE') {
                        device.rangeDevice = info.devices
                    }
                    info.redirectServerStrategys.map(item => {
                        if (item.aim === 'NMS') {
                            formState2.NMSIp = item.ip
                            formState2.NMSPort = item.port
                        }
                        if (item.aim === 'DRP') {
                            formState2.DRPIp = item.ip
                            formState2.DRPPortt = item.port
                        }
                        if (item.aim === 'DMCLD') {
                            formState2.DMCLDIp = item.ip
                            formState2.DMCLDPort = item.port
                        }
                    })
                    formState3.url = info.provision.url
                    formState3.login = info.provision.username
                    formState3.desc = info.redirect.description
                    formState3.state = info.redirect.ifEnable

                }

                await device.getDeviceALl()
                await store.dispatch('device/changeChoiceGroupArr', {id:formState.user})
            })

            return {
                userChange,
                formState,
                formState2,
                formState3,
                rules,
                beforeUpload,
                rangeDeviceLeftColumns: [
                    {title: t('device.sn'), width: 200, dataIndex: 'deviceSn', ellipsis: true,},
                    {title: t('device.mac'), width: 200, dataIndex: 'mac', ellipsis: true,},
                ],
                rangeDeviceRightColumns: [
                    {title: t('device.sn'), width: 200, dataIndex: 'deviceSn', ellipsis: true,},
                ],
                rowSelection,
                formRef,
                formRef2,
                formRef3,
                ifAdmin,
                device,
                async onOk() {
                    try {

                        await formRef.value.validate()
                        await formRef2.value.validate()
                        await formRef3.value.validate()
                        await validator.range()
                        await validator.server()
                        /*if(formState3.url){
                            if(!formState3.login){
                                return message.error(t('plz.inputLogin'))
                            }
                            if(!formState3.pwd){
                                return message.error(t('plz.inputPwd'))
                            }
                        }*/
                        let data = toRaw({...formState, ...formState2, ...formState3})
                        await add({
                            name: data.name,
                            desc: data.desc,
                            status: data.state,
                            uid: data.user,
                            url: data.url,
                            username: data.login,
                            pwd: data.pwd,
                            scope: data.range,
                            scopeValue: ((scope) => {
                                switch (scope) {
                                    case "GLOBAL": {
                                        return []
                                    }
                                    case "SPECIFY_DEVICE": {
                                        return device.rangeDevice.map(r=>r.id)
                                    }
                                    case "SPECIFY_GROUP": {
                                        return [data.rangeGroup]
                                    }
                                }
                            })(data.range),
                            strategies: ((data) => {
                                let arr = []
                                if (data.NMSIp && data.NMSPort) {
                                    arr.push({
                                        "aim": "NMS",
                                        "ip": data.NMSIp,
                                        "port": data.NMSPort
                                    })
                                }
                                if (data.DRPIp && data.DRPPort) {
                                    arr.push({
                                        "aim": "DRP",
                                        "ip": data.DRPIp,
                                        "port": data.DRPPort
                                    })
                                }
                                if (data.DMCLDIp && data.DMCLDPort) {
                                    arr.push({
                                        "aim": "DMCLD",
                                        "ip": data.DMCLDIp,
                                        "port": data.DMCLDPort
                                    })
                                }
                                return arr
                            })(data),
                        })
                        message.success(t('redirect.addOk'))
                        await formRef.value.resetFields()
                        await formRef2.value.resetFields()
                        await formRef3.value.resetFields()
                        store.dispatch('appTabs/removeTabs', {routeName: route.name})
                        await router.go(-1)
                    } catch (e) {
                        console.log(e)
                    }
                },
                async onEditOk() {
                    try {
                        await validator.range()
                        await validator.server()
                        await formRef.value.validate()
                        await formRef2.value.validate()
                        await formRef3.value.validate()
                        let data = toRaw({...formState, ...formState2, ...formState3})
                        await update({
                            id: route.query.id,
                            desc: data.desc,
                            status: data.state,
                            uid: data.user,
                            url: data.url,
                            username: data.login,
                            pwd: data.pwd,
                            scope: data.range,
                            scopeValue: ((scope) => {
                                switch (scope) {
                                    case "GLOBAL": {
                                        return []
                                    }
                                    case "SPECIFY_DEVICE": {
                                        return device.rangeDevice.map(r=>r.id)
                                    }
                                    case "SPECIFY_GROUP": {
                                        return [data.rangeGroup]
                                    }
                                }
                            })(data.range),
                            strategies: ((data) => {
                                let arr = []
                                if (data.NMSIp && data.NMSPort) {
                                    arr.push({
                                        "aim": "NMS",
                                        "ip": data.NMSIp,
                                        "port": data.NMSPort
                                    })
                                }
                                if (data.DRPIp && data.DRPPort) {
                                    arr.push({
                                        "aim": "DRP",
                                        "ip": data.DRPIp,
                                        "port": data.DRPPort
                                    })
                                }
                                if (data.DMCLDIp && data.DMCLDPort) {
                                    arr.push({
                                        "aim": "DMCLD",
                                        "ip": data.DMCLDIp,
                                        "port": data.DMCLDPort
                                    })
                                }
                                return arr
                            })(data),
                        })
                        message.success(t('updateOk'))
                        await formRef.value.resetFields()
                        await formRef2.value.resetFields()
                        await formRef3.value.resetFields()
                        store.dispatch('appTabs/removeTabs', {routeName: route.name})
                        await router.go(-1)
                    } catch (e) {
                        console.log(e)
                    }
                },
                async onCancel() {
                    await formRef.value.resetFields()
                    await formRef2.value.resetFields()
                    await formRef3.value.resetFields()
                    store.dispatch('appTabs/removeTabs', {routeName: route.name})
                    await router.go(-1)
                },
                async handleSearch(e){
                    await store.dispatch('user/search', {searchForm: {search: e}, current: 1, search: true})
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .redirect-form {
        background: #ffffff;
    }
</style>